import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'

import Services from '../services.js'
import ItemCategories from '../enums/ItemCategories'

const separator = ", dopo cottura: "


const Menu = (props) => {
    const [menu, setMenu] = useState([])

    async function retrieveMenu() {
        if (props.user.username) {
            await Services.getAllMenu(props.user.username)
                .then((response) => {
                    // console.log(response.data.menu)
                    setMenu(response.data.menu)
                })
                .catch((error) => {
                    console.log(error)
                    setMenu([])
                })

            searchRef.current.focus()
        } else {
            setMenu([])
        }
    }

    useEffect(() => {
        retrieveMenu().then(() => {
            console.log("piatti recuperati")
        }, (err) => console.log(err))
    }, [])

    const [menuTitle, setMenuTitle] = useState("")
    const [menuDescription, setMenuDescription] = useState("")
    const [menuDescriptionModal, setMenuDescriptionModal] = useState(false)
    const [menuPrice, setMenuPrice] = useState("")
    const [menuCategory, setMenuCategory] = useState("")
    const [menuSubcategory, setMenuSubcategory] = useState("")
    const [menuImage, setMenuImage] = useState("")
    const [fileName, setFileName] = useState("")
    const [csvContent, setCsvContent] = useState("")
    const [csvFileName, setCsvFileName] = useState("")
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    const onChangeMenuTitle = (e) => {
        const menuTitle = e.target.value.replace(',', '')
        setMenuTitle(menuTitle)
    }
    const onChangeMenuDescription = (e) => {
        const menuDescription = e.target.value
        setMenuDescription(menuDescription)
    }
    const onChangeMenuPrice = (e) => {
        const menuPrice = e.target.value
        setMenuPrice(menuPrice)
    }
    const onChangeMenuCategory = (e) => {
        const menuCategory = e.target.value
        setMenuCategory(menuCategory)
    }
    const onChangeMenuSubcategory = (e) => {
        const menuSubcategory = e.target.value
        setMenuSubcategory(menuSubcategory)
    }

    const [currentId, setCurrentId] = useState("")
    const [currentItem, setCurrentItem] = useState(null)

    // AGGIORNAMENTO PIATTO SALVATO
    useEffect(() => {
        if (currentItem) {
            setMenuTitle(currentItem.name ?? "")
            setMenuDescription(currentItem.description ?? "")
            setMenuPrice(currentItem.price ? String(currentItem.price) : (currentItem.price === 0 ? "0" : ""))  // in questo modo funziona anche per prezzi negativi
            setMenuCategory(currentItem.category ?? "")
            setMenuSubcategory(currentItem.subcategory ?? "")
            setMenuImage(currentItem.image ?? "")

            if (currentItem.description) {
                const desc = currentItem.description.split(separator)
                let descIn = desc[0].split(/,/)
                descIn.forEach((item, index, arr) => arr[index] = item.trim())
                let descAfter = desc[1]?.split(/,/)
                for (let i in descAfter) {
                    descIn.push([descAfter[i].trim()])
                }
                setCurrentPlate(descIn)
            } else {
                setCurrentPlate([])
            }
        } else {
            setMenuTitle("")
            setMenuDescription("")
            setMenuPrice("")
            setMenuCategory("")
            setMenuSubcategory("")
            setMenuImage("")
            setFileName("")
            setCurrentPlate([])
        }
    }, [currentItem])

    // COSTRUZIONE PIATTO TRAMITE TESTO LIBERO (IN TEMPO REALE)
    useEffect(() => {
        if (menuDescription) {
            const desc = menuDescription.split(separator)
            let descIn = desc[0].split(/,/)
            descIn.forEach((item, index, arr) => arr[index] = item.trim())
            let descAfter = desc[1]?.split(/,/)
            for (let i in descAfter) {
                descIn.push([descAfter[i].trim()])
            }
            setCurrentPlate(descIn)
        } else {
            setCurrentPlate([])
        }
    }, [menuDescription])

    const menuInsert = (menuDescription) => {
        if (props.user.username && menuTitle && menuPrice && menuCategory) {
            Services.menuInsert(
                props.user.username,
                menuTitle,
                (menuCategory !== ItemCategories.INGREDIENTI ? menuDescription : ""),
                menuPrice.replace(',', '.'),
                menuCategory,
                menuSubcategory,
                fileName ? menuImage : ""
            ).then((response) => {
                setMenuTitle("")
                setMenuDescription("")
                setMenuPrice("")
                setMenuCategory("")
                setMenuSubcategory("")
                setMenuImage("")
                setFileName("")

                retrieveMenu().then(() => console.log("menu aggiornato"), (err) => console.log(err))
                props.setTriggerMenuUpdate(Date.now())

                setShow(false)
                setMenuDescriptionModal(false)
                setCurrentPlate([])
            })
            .catch((error) => {
                setBody(error.response ? error.response.data.error : "Server error.")
                setShow(true)
            })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
    }
    const menuUpdate = (menuDescription) => {
        if (props.user.username && menuTitle && menuPrice && menuCategory) {
            Services.menuUpdate(
                currentItem['_id'],
                props.user.username,
                menuTitle,
                (menuCategory !== ItemCategories.INGREDIENTI ? menuDescription : ""),
                menuPrice.replace(',', '.'),
                menuCategory,
                menuSubcategory,
                fileName ? menuImage : ""
            ).then((response) => {
                setMenuTitle("")
                setMenuDescription("")
                setMenuPrice("")
                setMenuCategory("")
                setMenuSubcategory("")
                setMenuImage("")
                setFileName("")

                retrieveMenu().then(() => console.log("menu aggiornato"), (err) => console.log(err))
                props.setTriggerMenuUpdate(Date.now())

                setShow(false)
                setCurrentItem(null)
                setMenuDescriptionModal(false)
                setCurrentPlate([])
            })
            .catch((error) => {
                setBody(error.response ? error.response.data.error : "Server error.")
                setShow(true)
            })
        } else {
            setBody("Non hai riempito i campi obbligatori.")
            setShow(true)
        }
    }
    const menuDelete = (id) => {
        if (props.user.username) {
            Services.menuDelete(id, props.user.username)
                .then((response) => {
                    retrieveMenu().then(() => console.log("menu aggiornato"), (err) => console.log(err))
                    props.setTriggerMenuUpdate(Date.now())

                    setShow(false)
                })
                .catch((error) => {
                    setBody(error.response ? error.response.data.error : "Non è stato possibile eliminare il record.")
                    setShow(true)
                })
        }
    }
    const hideItemInApp = (id, hide) => {
        if (props.user.username) {
            Services.hideItemInApp(id, props.user.username, hide)
                .then((response) => {
                    console.log(response.status)  // non viene recuperato il menu perché ho una mappatura 1:1 tra la proprietà hide e checked
                })
                .catch((error) => {
                    console.log(error.response?.data?.error)
                })
        }
    }
    const menuImport = () => {
        if (props.user.username) {
            Services.menuImport(props.user.username, csvContent)
                .then((response) => {
                    retrieveMenu().then(() => console.log("menu aggiornato"), (err) => console.log(err))
                    props.setTriggerMenuUpdate(Date.now())

                    setShow(false)
                })
                .catch((error) => {
                    setBody(error.response ? error.response.data.error : "Non è stato possibile importare i record.")
                    setShow(true)
                })
            setCsvContent("")
        }
    }

    // TABELLA CON PAGINAZIONE E RICERCA
    const columns = useMemo(
        () => [
            {
                Header: 'Foto',
                accessor: 'image',
                Cell: props => props.value ? <img src={props.value} width="50" height="50" alt={props.row.original["name"]} /> : <></>
            },
            {
                Header: 'Categoria',
                accessor: 'category'
            },
            {
                Header: 'Sotto-categoria',
                accessor: 'subcategory'
            },
            {
                Header: 'Nome',
                accessor: 'name'
            },
            {
                Header: 'Descrizione',
                accessor: 'description'
            },
            {
                Header: 'Prezzo',
                accessor: 'price',
                Cell: props => <span>€ {props.value.toFixed(2)}</span>
            },
            {
                Header: 'Visibile in App',
                accessor: 'hide',
                Cell: props => <div className="text-center"><input
                    type="checkbox"
                    defaultChecked={!(props.value === true)}  // se hide non esiste è come se fosse false (quindi checked=true perché visibile)
                    onClick={(e) => hideItemInApp(props.row.original["_id"], !e.target.checked)}
                ></input></div>
            }
        ],
        [menu]
    )

    const memoizedMenu = useMemo(() => menu, [menu])
    const searchRef = useRef(null)

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
            searchRef.current.focus()
        }, 1000)  // millisecondi dopo cui parte la ricerca

        return (
            <span>
                Cerca:{' '}
                <input
                    className="form-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} risultati...`}
                    ref={searchRef}
                />
            </span>
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns: columns,
            data: memoizedMenu,
            initialState: { pageIndex: 0, pageSize: 50 }
        },
        //useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    // DESCRIZIONE
    const [plates, setPlates] = useState([])
    const [currentPlate, setCurrentPlate] = useState([])
    const [cottura, setCottura] = useState("IN")
    const [additionField, setAdditionField] = useState("")

    const onChangeCottura = (e) => {
        const cottura = e.target.value
        setCottura(cottura)
    }
    const onChangeAdditionField = (e) => {
        const entry = e.target.value.toLowerCase()
        setAdditionField(entry)

        const plates = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()
            return nameLower.includes(entry)
        })
        setPlates(plates)
    }
    const addIngredient = () => {
        //find ingrediente tramite nome
        const ingredients = memoizedMenu.filter((m) => {
            const nameLower = m.name.toLowerCase()
            if (m.category === ItemCategories.INGREDIENTI) {  //considero solo gli ingredienti e non le pizze/bibite omonime
                return nameLower === additionField
            } else {
                return false
            }
        })

        if (ingredients.length > 0) {
            let newCurrentPlate = []

            // CONVENZIONE: gli ingredienti dopo cottura li inserisco in un array singolo per distinguerli successivamente
            if (cottura === "IN") {
                newCurrentPlate = [...currentPlate, ingredients[0].name]
            } else {
                newCurrentPlate = [...currentPlate, [ingredients[0].name]]
            }
            
            setCurrentPlate(newCurrentPlate)
            setAdditionField("")
            setPlates([])
        }
    }
    const removeIngredient = (index) => {
        const newCurrentPlate = [...currentPlate]
        newCurrentPlate.splice(index, 1)
        setCurrentPlate(newCurrentPlate)
    }
    const saveDescription = () => {
        let desc = []
        let descAfter = []
        for (let i in currentPlate) {
            if (typeof currentPlate[i] === "object") {
                descAfter.push(currentPlate[i][0])
            } else {
                desc.push(currentPlate[i])
            }
        }
        console.log(currentPlate, desc, descAfter)

        if (descAfter.length > 0) {
            return desc.join(", ") + separator + descAfter.join(", ")
        } else {
            return desc.join(", ")
        }
    }

    // TOOLTIPS
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
    }, [])

    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Listino</h6>
                </div>
                <div className="card-body text-dark">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="form-group row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-success btn-font-sm"
                                data-toggle="modal"
                                data-target="#descriptionModal"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                                title="Aggiungi"
                            >
                                <i className="fa fa-plus"></i>
                                <span className="ml-2 d-none d-sm-inline">Aggiungi</span>
                            </button>
                            &nbsp;
                            <button
                                type="button"
                                className="btn btn-warning btn-font-sm"
                                onClick={async () => {
                                    if (props.user.username) {
                                        await Services.menuExport(props.user.username)
                                            .then((response) => {
                                                document.getElementById('link').href = "data:text/plain;charset=utf-8," + encodeURIComponent(response.data)
                                                document.getElementById('link').click()
                                            })
                                            .catch((error) => {
                                                console.log(error.response?.data?.error)
                                            })
                                    }
                                }}
                                title="Esporta"
                            >
                                <i className="fas fa-file-download"></i>
                                <span className="ml-2 d-none d-sm-inline">Esporta</span>
                            </button>
                            <a href="data:text/plain;charset=utf-8," download="Menu.csv" id="link"></a>
                            &nbsp;
                            <button
                                type="button"
                                className="btn btn-primary btn-font-sm"
                                data-toggle="modal"
                                data-target="#importModal"
                                title="Importa"
                            >
                                <i className="fas fa-file-upload"></i>
                                <span className="ml-2 d-none d-sm-inline">Importa</span>
                            </button>
                        </div>
                    </div>
                    
                    <div className="table-responsive text-dark">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                        &nbsp;
                        <table className="table text-dark" {...getTableProps()} style={{ width: "100%" }}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                {/* sort direction indicator */}
                                                <span>
                                                    &nbsp;
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <i className="fas fa-sort-down"></i>
                                                            : <i className="fas fa-sort-up"></i>
                                                        : <i className="fas fa-sort"></i>}
                                                </span>
                                            </th>
                                        ))}

                                        {/* AZIONI */}
                                        <th style={{ width: "10%" }}>Azioni</th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}

                                            {/* AZIONI */}
                                            <td>
                                                <button
                                                    className="btn btn-warning btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#descriptionModal"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={() => setCurrentItem(memoizedMenu[row.index])}
                                                    title="Modifica"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#modalCancellaRecord"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={() => setCurrentId(memoizedMenu[row.index]['_id'])}
                                                    title="Cancella"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* Pagination */}
                        <ul className="pagination">
                            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <button className="page-link">Inizio</button>
                            </li>
                            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <button className="page-link">{'<'}</button>
                            </li>
                            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                                <button className="page-link">{'>'}</button>
                            </li>
                            <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <button className="page-link">Fine</button>
                            </li>
                            <li>
                                <span className="page-link">
                                    Pagina{' '}
                                    <strong>
                                        {pageIndex + 1} di {pageOptions.length}
                                    </strong>{' '}
                                </span>
                            </li>
                            <li>
                                <span className="page-link">
                                    <input
                                        className="form-control"
                                        type="number"
                                        value={pageIndex ? pageIndex + 1 : 1}
                                        min={1}
                                        max={pageOptions.length}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '100px', height: '20px' }}
                                    />
                                </span>
                            </li>
                            &nbsp;
                            <select
                                className="form-control"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                style={{ width: '125px', height: '38px' }}
                            >
                                {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Mostra: {pageSize}
                                    </option>
                                ))}
                            </select>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="descriptionModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Inserisci / aggiorna record del listino</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setMenuCategory("")
                                    setMenuSubcategory("")
                                    setMenuTitle("")
                                    setMenuDescription("")
                                    setMenuPrice("")
                                    setMenuImage("")
                                    setFileName("")
                                    setCurrentPlate([])
                                    setShow(false)
                                    setCurrentItem(null)
                                    setMenuDescriptionModal(false)
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <label htmlFor="menuCategory" className="col-form-label col-4 d-flex justify-content-end"><b>Categoria</b></label>
                                <div className="col-8">
                                    <select
                                        id="menuCategory"
                                        className={(show && !menuCategory) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={menuCategory}
                                        onChange={onChangeMenuCategory}
                                    >
                                        <option value="">Seleziona la categoria</option>
                                        <option value={ItemCategories.INGREDIENTI}>Ingredienti</option>

                                        <option value={ItemCategories.PIZZE}>Pizze</option>
                                        <option value={ItemCategories.BIBITE}>Bibite</option>
                                        <option value={ItemCategories.DOLCI}>Dolci</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="menuSubcategory" className="col-form-label col-4 d-flex justify-content-end"><b>Sotto-categoria</b></label>
                                <div className="col-8">
                                    <select
                                        id="menuSubcategory"
                                        className="form-control form-control-user"
                                        value={menuSubcategory}
                                        onChange={onChangeMenuSubcategory}
                                    >
                                        <option value="">Sotto-categoria (opzionale)</option>
                                        {(menuCategory && props.settings?.delivery?.subcategories && props.settings.delivery.subcategories[menuCategory]) &&
                                            props.settings.delivery.subcategories[menuCategory]?.map((sc, i) => {
                                                return (
                                                    <option key={`sottocategoria${i}`} value={sc}>{sc}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="menuTitle" className="col-form-label col-4 d-flex justify-content-end"><b>Nome</b></label>
                                <div className="col-8">
                                    <input
                                        id="menuTitle"
                                        type="text"
                                        className={(show && !menuTitle) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        placeholder="Inserire un nome"
                                        value={menuTitle}
                                        onChange={onChangeMenuTitle}
                                        aria-required="true"
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="menuPrice" className="col-form-label col-4 d-flex justify-content-end">
                                    <span
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Per diversificare il prezzo di un ingrediente a seconda che venga usato in o dopo cottura dovrai inserire un nuovo
                                               ingrediente con la dicitura 'Dopo cottura: ' nel nome e il prezzo differente"
                                        style={{ textDecoration: "underline 1px dotted" }}
                                    >
                                        <b>Prezzo</b>
                                    </span>
                                </label>
                                <div className="col-8">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">€</span>
                                        </div>
                                        <input
                                            id="menuPrice"
                                            type="number"
                                            step="0.01"
                                            className={(show && !menuPrice) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                            placeholder="Inserire un importo"
                                            value={menuPrice}
                                            onChange={onChangeMenuPrice}
                                            aria-required="true"
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="fileupload" className="col-form-label col-4 d-flex justify-content-end"><b>{menuImage ? "Cambia foto" : "Carica foto"}</b></label>
                                <div className="col-form-label col-8 d-flex">
                                    <input id="fileupload" type="file" accept="image/png, image/jpeg" value={fileName} onChange={async (event) => {
                                        setFileName(event.target.value)

                                        // https://stackoverflow.com/questions/36580196/reactjs-base64-file-upload
                                        const file = event.target.files[0]
                                        const convertBase64 = (file) => {
                                            return new Promise((resolve, reject) => {
                                              const fileReader = new FileReader();
                                              fileReader.readAsDataURL(file)
                                              fileReader.onload = () => {
                                                resolve(fileReader.result);
                                              }
                                              fileReader.onerror = (error) => {
                                                reject(error);
                                              }
                                            })
                                        }
                                        await convertBase64(file).then((base64) => {
                                            setMenuImage(base64)
                                        }, (err) => {
                                            // quando scelgo un file e dopo, nel cambiarlo, faccio annulla: rimane settato il primo file
                                            console.log(err)
                                        })
                                        
                                    }} />
                                </div>
                            </div>
                            <br />
                            <div className="form-group row">
                                <div className="col-6">
                                    <input type="radio" id="Inserisci" name="category" checked={!menuDescriptionModal} onChange={() => setMenuDescriptionModal(false)}></input>
                                    <label htmlFor="Inserisci">&nbsp;<b>Inserisci descrizione</b></label>
                                </div>
                                <div className="col-6">
                                    <input type="radio" id="Configura" name="category" checked={menuDescriptionModal} onChange={() => setMenuDescriptionModal(true)}></input>
                                    <label htmlFor="Configura">&nbsp;<b>Configura descrizione</b></label>
                                </div>
                            </div>

                            {menuDescriptionModal ? (
                                <>
                                    <div className="form-group row">
                                        <label htmlFor="cottura" className="col-form-label col-4 d-flex justify-content-end">Tipo cottura:</label>
                                        <div className="col-8">
                                            <select
                                                id="cottura"
                                                className="form-control form-control-user"
                                                value={cottura}
                                                onChange={onChangeCottura}
                                                disabled={!menuCategory || menuCategory === ItemCategories.INGREDIENTI}
                                            >
                                                <option value="IN">In cottura</option>
                                                <option value="AFTER">Dopo cottura</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="aggiunte" className="col-form-label col-4 d-flex justify-content-end">Ingrediente:</label>
                                        <div className="col-8">
                                            <input
                                                list="listaingredienti"
                                                id="aggiunte"
                                                type="text"
                                                className="form-control form-control-user"
                                                placeholder="Inserire un ingrediente"
                                                value={additionField}
                                                onChange={onChangeAdditionField}
                                                onSelect={addIngredient}
                                                disabled={!menuCategory || menuCategory === ItemCategories.INGREDIENTI}
                                            ></input>
                                            <datalist id="listaingredienti">
                                                {plates.map((p, index) => {
                                                    if (p.category === ItemCategories.INGREDIENTI) {  //mostro solo gli ingredienti
                                                        return (
                                                            <option key={`listaingredienti${index}`} value={p.name}>{p.name}</option>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </datalist>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="form-group row">
                                    <div className="col-12">
                                        <textarea
                                            id="descrizione"
                                            className="form-control form-control-user"
                                            placeholder="Inserisci la descrizione rispettando il formato 'Ingredienti in cottura[, dopo cottura: ingredienti dopo cottura]'"
                                            rows="5"
                                            value={menuDescription}
                                            onChange={onChangeMenuDescription}
                                            disabled={!menuCategory || menuCategory === ItemCategories.INGREDIENTI}
                                        ></textarea>
                                    </div>
                                </div>
                            )}
                            {currentPlate?.length > 0 && <>
                                <hr />
                                <h5><b><i>Ingredienti inseriti</i></b></h5>
                                {currentPlate.map((i, index) => {
                                    let ingredient = i
                                    let note = ""
                                    if (typeof ingredient === "object") {
                                        ingredient = i[0]
                                        note = "Dopo cottura: "
                                    }

                                    return (
                                        <div className="row" key={`ingredienti-${index}`}>
                                            <div className="col-8">
                                                <span>{note}{ingredient}</span>
                                            </div>
                                            <div className="col-4">
                                                {menuDescriptionModal &&
                                                    <button type="button" className="btn btn-danger btn-circle btn-sm" onClick={() => removeIngredient(index)}>
                                                        <i className="fas fa-trash"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </>}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    setMenuCategory("")
                                    setMenuSubcategory("")
                                    setMenuTitle("")
                                    setMenuDescription("")
                                    setMenuPrice("")
                                    setMenuImage("")
                                    setFileName("")
                                    setCurrentPlate([])
                                    setShow(false)
                                    setCurrentItem(null)
                                    setMenuDescriptionModal(false)
                                }}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    const desc = saveDescription()
                                    if (currentItem) {
                                        menuUpdate(desc)
                                    } else {
                                        menuInsert(desc)
                                    }
                                }}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="importModal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Importa Menu</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {setCsvContent("") ; setCsvFileName("")}}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <label htmlFor="csvupload" className="col-form-label col-4 d-flex"><b>1. Compila il file</b></label>
                                <div className="col-form-label col-8 d-flex">
                                    <a 
                                        href={"data:text/plain;charset=utf-8," + encodeURIComponent("name,description,price,category,subcategory,image,hide")}
                                        download="Menu.csv"
                                    >
                                        Scarica file base
                                    </a>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="csvupload" className="col-form-label col-4 d-flex"><b>2. Carica il file</b></label>
                                <div className="col-form-label col-8 d-flex">
                                    <input id="csvupload" type="file" accept="text/csv" value={csvFileName} onChange={async (event) => {
                                        setCsvFileName(event.target.value)

                                        // https://stackoverflow.com/questions/36580196/reactjs-base64-file-upload
                                        const file = event.target.files[0]
                                        const convertBase64 = (file) => {
                                            return new Promise((resolve, reject) => {
                                              const fileReader = new FileReader();
                                              fileReader.readAsDataURL(file)
                                              fileReader.onload = () => {
                                                resolve(fileReader.result);
                                              }
                                              fileReader.onerror = (error) => {
                                                reject(error);
                                              }
                                            })
                                        }
                                        await convertBase64(file).then((base64) => {
                                            setCsvContent(base64)
                                        }, (err) => {
                                            // quando scelgo un file e dopo, nel cambiarlo, faccio annulla: rimane settato il primo file
                                            console.log(err)
                                        })
                                        
                                    }} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {setCsvContent("") ; setCsvFileName("")}}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {menuImport() ; setCsvContent("") ; setCsvFileName("")}}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA RECORD --> */}
            <div className="modal fade" id="modalCancellaRecord" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella record</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentId("")}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare il record selezionato?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentId("")}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {menuDelete(currentId) ; setCurrentId("")}}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CANCELLA RECORD --> */}

        </div>
    )
}

export default Menu
